export default (list: any[] | null | undefined) => {
  return (
    list?.map((widget) => {
      const name = `Section${widget?.entity?.__typename.replace('Paragraph', '')}`
      return {
        name,
        entity: widget?.entity,
      }
    }) || []
  )
}
